import React from 'react';

const SEO = (props) => {
  // avoid double keyphrase 

  const description =  props.meta_description || 
    'Finde hier deinen passenden Tiny House Hersteller an Hand von Erfahrungsberichten und nützlichen Informationen.';
  const title =  props.title || 'Finde Hersteller und mehr';

 if(process.env.DEVELOP === 'true' ) {
    console.log('SEO meta description: ', description);
    console.log('SEO title: ', title);

   if(!title || !description) {
    console.warn('SEO WARNING: At least one meta info is not set! Check SEO Component.');
   } else if (description.length > 160) {
     console.warn('SEO WARNING: Meta description is longer than 160 chars: ', description.length);
   } else if (title.length > 60) {
      console.warn('SEO WARNING: Title is longer than 60 chars: ', title.length);
  }
 } 

 let ogTags = null;
 if(props.yoast_og_meta) {
  // debugger;
   ogTags = props.yoast_og_meta.map((el, i) => {
     let content = el.content;
    if(el.property === "og:url") {
      content = content.replace(/\/wp-inhalte/g, '').replace(/\/\/tiny/g, '//www.tiny');
    }
    return <meta key={i} property={el.property} content={content} />;
   });
 }
//  debugger;
  return (
    <>          
      <title>{title}</title>
      <meta 
        name="description" 
        content={description}
      />                        
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
      <link rel="manifest" href="/site.webmanifest"/>
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000"/>
      <meta name="msapplication-TileColor" content="#2b5797"/>
      <meta name="theme-color" content="#e1d7bf"/>
      
      {props.noIndex ? <meta name="ROBOTS" CONTENT="NOINDEX,FOLLOW" /> : null }
      {props.meta_thumbnail ? <meta name="thumbnail" content={props.meta_thumbnail} /> : null }
      {ogTags ? ogTags : null}
    </>
  );

}
export default SEO;


export function Head(props) {
  return (
    props.children
  )
}
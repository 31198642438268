import React from "react"
import Layout from "../components/Layout/Layout"
import { Link } from "gatsby";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import Seo from "../components/SEO/SEO";
// https://developer.wordpress.org/rest-api/reference/comments/
const About = ( {data} ) => {
  return (
    <>
    <Layout 
      headlines={["Über uns"]} 
      className="centeredPage--TextPage"
    > 
      <h1>Das sind wir – die Tiny House Helden</h1>
      <p>
        <em>
          Zun&auml;chst einmal m&ouml;chten wir sagen, dass es uns sehr freut,
          dass du dich f&uuml;r das Thema Tiny House interessierst! Lass dich
          nicht von den gesetzlichen H&uuml;rden oder Sonstigem verunsichern,
          sondern mach weiter &ndash; wie ein richtiger Held {" "}
        </em>
        🙂
      </p>
      {/* <GatsbyImage alt="Agi und Michi" fluid={data.agaMichiImg.childImageSharp.fluid}/>  */}
      <p>
        Das Tiny House Helden Projekt startete ich (Michael) urpsrünglich mit meiner damaligen Partnerin Agnieszka. Das Thema hatte uns seit unserer Reise durch Neuseeland und Australien nicht mehr losgelassen. Als wir uns n&auml;her damit
        besch&auml;ftigt haben, mussten wir aber schnell feststellen, dass der
        Traum vom eigenen Tiny House mit einem sch&ouml;nen Stellplatz nicht mal
        eben so zu realisieren ist. Gerade in Deutschland gibt es wirklich viele
        Dinge zu beachten. Bei den Recherchen fiel uns auch auf, dass es oftmals
        nicht so einfach ist, an brauchbare Informationen zu kommen. Gerade der
        Erfahrungsaustausch über Hersteller erschien uns besonders wichtig. 
        Daher haben wir das Projekt<em> Tiny House Helden</em> ins Leben gerufen.
      </p>
      <p>
        Auf dieser Website m&ouml;chte ich nun mit dir unser Wissen teilen und dir auch
        dabei helfen, deinen Wunsch vom eigenen Tiny House zu erfüllen.
        Dabei recherchiere ich sorgf&auml;ltig und versuche dir wirklich
        brauchbare Informationen zu geben. Auch du kannst gerne zu dem Projekt
        beitragen, indem du z. B. deine Erfahrung mit einem Hersteller teilst (mehr hierzu unter <Link to="/mitmachen/">mitmachen</Link>).
      </p>

      <p>
        Der Inhalt der Website wird best&auml;ndig erweitert oder angepasst, da
        ich immer wieder Neues zum Thema dazulerne und auch in engem
        Kontakt mit Tiny House Herstellern und Besitzern stehe. Meine großartige
        Zeit im Tiny House Village in Mehlmeisel hat mich auch einiges über das Thema Tiny House gelehrt.
        
        Es lohnt sich also regelm&auml;ßig vorbeizuschauen und im {" "}
        <Link to="/newsletter/">Newsletter</Link> einzutragen.
      </p>
    </Layout>
    </>
  )
};

export default About;


// GraphQL 
export const query = graphql`
query {
  agaMichiImg: file(relativePath: { eq: "images/aboutus/agi-und-michi.jpg" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 700) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}`

export function Head() { 

  return(
    <Seo 
      title = 'Das sind wir | Tiny House Helden'
      meta_description =
        'Erfahre, wer die Tiny House Helden sind bzw. worum es sich bei den Tiny House Helden handelt'
    />
  )
}